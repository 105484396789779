import { graphql, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

import appleTouchIcon from '../../../assets/icon/favicons/apple-touch-icon.png';
import favicon16 from '../../../assets/icon/favicons/favicon-16x16.png';
import favicon32 from '../../../assets/icon/favicons/favicon-32x32.png';

type MetaItem = {
  name: string;
  content: string;
};

type SEOProps = {
  title?: string;
  lang?: string;
  description?: string;
  url?: string;
  author?: string;
  keywords?: string[];
  meta?: MetaItem[];
  image?: string;
};

const SEO: FC<SEOProps> = props => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
          author
          url
          keywords
        }
      }
    }
  `);

  const { siteMetadata } = data.site;

  const { title, description, url, author, meta = [], keywords = [], image } = siteMetadata;

  const siteTitle = props.title || title;
  const siteLang = props.lang || 'en-GB';
  const siteDescription = props.description || description;
  const siteUrl = props.url || url;
  const siteAuthor = props.author || author;
  const siteImage = props.image || image;
  const siteKeywords = [...keywords, props.keywords].join(',');

  const metaData = [
    {
      name: 'canonical',
      content: siteUrl,
    },
    {
      name: 'description',
      content: siteDescription,
    },
    {
      name: 'image',
      content: siteImage,
    },
    {
      name: 'og:url',
      content: siteUrl,
    },
    {
      name: 'og:type',
      content: 'article',
    },
    {
      name: 'og:title',
      content: siteTitle,
    },
    {
      name: 'og:description',
      content: siteDescription,
    },
    {
      name: 'og:image',
      content: siteImage,
    },
    {
      name: 'twitter:card',
      content: 'summary_large_image',
    },
    {
      name: 'twitter:creator',
      content: siteAuthor,
    },
    {
      name: 'twitter:title',
      content: siteTitle,
    },
    {
      name: 'twitter:description',
      content: siteDescription,
    },
    {
      name: 'twitter:image',
      content: siteImage,
    },
    {
      name: 'keywords',
      content: siteKeywords,
    },
    {
      name: 'msapplication-TileColor',
      content: '#ffffff',
    },
    {
      name: 'theme-color',
      content: '#ffffff',
    },
  ];

  const linkData = [
    {
      rel: 'shortcut icon',
      href: 'favicon.ico',
    },
    {
      rel: 'apple-touch-icon',
      href: appleTouchIcon,
      size: '180x180',
    },
    {
      rel: 'icon',
      href: favicon32,
      size: '32x32',
      type: 'image/png',
    },
    {
      rel: 'icon',
      href: favicon16,
      size: '16x16',
      type: 'image/png',
    },
  ];

  return <Helmet htmlAttributes={{ lang: siteLang }} title={siteTitle} meta={metaData} link={linkData}></Helmet>;
};

export default SEO;
